<template>
  <div>

    <v-card>
      <v-card-title>Listado de tarifas</v-card-title>
      <v-card-text>

        <botonera :botones="botones" alinear="right"
        @crear="dialog = !dialog"
        @cargar="upload = !upload"
        @eliminar="deleteAll"
        ></botonera>

        
      </v-card-text>
    </v-card>


    <datatable :cabeceras="tarifasListHeaders" :items="tarifasListBody" titulo="Listado de Tarifas" label="Escriba Para buscar Tarifa" icono="list" color_icono="azul"
    :tachar="false"
    :acciones="['editar','eliminar']"
    @editar="editTarifa($event)"
    @eliminar="deleteTarifa($event)">
    </datatable>

    <!-- <v-data-table 
        :headers="tarifasListHeaders"
        :items="tarifasListBody"
        class="elevation-1"
        no-data-text="No hay tarifas"
        :search="search"
    >
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              @click="editTarifa(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar datos del Tarifa</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
                medium
                @click="deleteTarifa(item)"
                v-on="on"
            >
            mdi-delete
            </v-icon>
          </template>
          <span>Dar de baja Tarifa</span>
        </v-tooltip>
      </template>
    </v-data-table> -->

    <!-- Modal Nuevo Tarifa -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="tarifa.tipoacuerdo"
                :items="tipoacuerdos"
                item-text="nombre"
                item-value="id"
                label="Tipo Acuerdo"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="tarifa.empresa"
                :items="empresas"
                item-text="razon_social"
                item-value="id"
                label="Cliente"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4"
            >
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Milla"
                v-model="tarifa.milla"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4"
            >
              <v-text-field
                prepend-icon="mdi-currency-usd"
                placeholder="Valor USD"
                v-model="tarifa.valor"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4"
            >
              <v-text-field
                prepend-icon="mdi-percent"
                placeholder="% Descuento"
                v-model="tarifa.porc_descuento"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveTarifa"
              >Grabar</v-btn>
        </v-card-actions>
              
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de tarifas desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadTarifas"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nueva Tarifa',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      file: null,
      dialog: false,
      upload: false,
      empresas: [],
      tipoacuerdos: [],
      tarifaIndex: -1,
      tarifa: {
        id: 0,
        empresa: null,
        tipoacuerdo: null,
        milla: '',
        valor: '',
        porc_descuento: '',
      },
      tarifasListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Tipo Acuerdo',
          align: 'center',
          sortable: true,
          value: 'tipoacuerdo.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Cliente',
          align: 'center',
          sortable: true,
          value: 'empresa.razon_social',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Milla',
          align: 'center',
          sortable: true,
          value: 'milla',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Valor USD',
          align: 'center',
          sortable: true,
          value: 'valor',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Descuento',
          align: 'center',
          sortable: true,
          value: 'porc_descuento',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      tarifasListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nueva Tarifa'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        {boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadEmpresas();
      this.loadTipoAcuerdos();
      this.loadTarifas();
    },
    methods:{
      async loadEmpresas(){
        let url = `${this.base_url}empresas`;
        await this.axios.get(url, this.headers).then((response)=>{
            this.empresas = response.data;
            this.tarifa.empresa = this.empresas[0];
        }).catch((error)=>{
            console.log(error);
        });
      },
      async loadTipoAcuerdos(){
        let url = `${this.base_url}tipo_acuerdos`;
        await this.axios.get(url, this.headers).then((response)=>{
            this.tipoacuerdos = response.data;
            this.tarifa.tipoacuerdo = this.tipoacuerdos[0];
        }).catch((error)=>{
            console.log(error);
        });
      },
      async loadTarifas(){
        this.mostrarLoading('Cargando listado de tarifas...')
        let url = `${this.base_url}tarifas`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.tarifasListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveTarifa(){
        // validation
        console.log(this.tarifa);
        this.errors = [];
        if(!this.tarifa.milla || isNaN(this.tarifa.milla)){
          this.errors.push("Debe indicar la milla");
        }
        if(!this.tarifa.valor || isNaN(this.tarifa.valor)){
          this.errors.push("Debe indicar el valor en dolares");
        }
        if(!this.tarifa.porc_descuento || isNaN(this.tarifa.porc_descuento)){
          this.errors.push("Debe indicar el % de descuento");
        }
        if(this.tarifa.empresa == null){
          this.errors.push("Debe seleccionar un cliente");
        }
        if(this.tarifa.tipoacuerdo == null){
          this.errors.push("Debe seleccionar tipo de acuerdo");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}tarifas`;
        let tarifaData = {
          'empresas_id': this.tarifa.empresa.id,
          'tipo_acuerdos_id': this.tarifa.tipoacuerdo.id,
          'milla': this.tarifa.milla,
          'valor': this.tarifa.valor,
          'porc_descuento': this.tarifa.porc_descuento,
        };
        if(this.tarifa.id > 0){
          url = url + "/" + this.tarifa.id;
          await this.axios.put(url, tarifaData, this.headers).then((response)=>{
            let index = this.tarifasListBody.map(tarifa => {
              return tarifa.id;
            }).indexOf(this.tarifa.id);
            this.tarifasListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.validation_message = error;
            this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, tarifaData, this.headers).then((response)=>{
            this.tarifasListBody.push(response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.errors = error.response.data;
            this.validation_message = error;
            this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editTarifa(item) {
        this.tarifaIndex = this.tarifasListBody.indexOf(item);
        this.tarifa = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Tarifa";
      },
      async deleteTarifa(item) {
        this.$confirm('Desea borrar este tarifa?').then(res => {
          if(res){
            let url = `${this.base_url}tarifas/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              const index = this.tarifasListBody.indexOf(item);
              this.tarifasListBody.splice(index, 1);
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nueva Tarifa";
      },
      cleanForm(){
        this.tarifa.id = 0;
        this.tarifa.tipoacuerdo = null;
        this.tarifa.empresa = null;
        this.tarifa.milla = "";
        this.tarifa.valor = "";
        this.tarifa.porc_descuento = "";
        this.dialog = false;
      },
      async uploadTarifas(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/tarifas`;
        let formData = new FormData();
        formData.append("excel", this.file);
        await this.axios.post(url, formData, this.headers).then((response)=>{
          console.log(response.data.data);
          this.file = null;
          this.upload = false;
          this.loadTarifas();
          this.showSnackBar(false);
        }).catch((response)=>{
          if(response.success == false && response.message == "Validation Error"){
            this.validation_message = "Error de validación";
            this.showSnackBar(true);
          }
        });
      },
      async deleteAll(){
        this.$confirm('Desea borrar todos los datos?').then(res => {
          if(res){
            let url = `${this.base_url}truncar/tarifas`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.loadTarifas();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      
    }
  }
</script>